.item-photo {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.459);
}
.item-photo__content {
  max-width: max-content;
  max-height: max-content;
  padding: 20px;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
}
.item-fhoto__content__image {
  max-height: 90vh;
}
.item-none {
  display: none;
}

.item-fhoto__content__box-btn-close {
  display: flex;
  justify-content: flex-end;
}

.item-fhoto__content__box-btn-close__btn-close {
  background-color: rgb(0, 0, 0);
  color: white;
  border: none;
  font-size: x-large;
  margin-bottom: 10px;
}

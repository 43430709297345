.table-item__td {
  width: 20%;
  border-right: solid black 1px;
  border-top: solid black 1px;
  font-weight: 600;
}

.table-item__tr {
  height: 50px;
  border: 1px solid black;
  background-color: rgba(250, 228, 196, 0.589);
}
.table-item__input {
  width: 90%;
}

.link-fhoto {
  text-decoration: none;
  color: black;
}

.table-item__td__button {
  font-size: medium;
  font-weight: 600;
  border: solid 2px black;
  border-radius: 5px;
  margin: 5px;
  width: 80px;
}

.table-item__td__button:hover {
  background-color: #ff7b00a9;
}
.main-page__table-title {
    font-weight: 600;
    font-size: 18px;
    border: solid 2px black;
    border-bottom: none;
  }
  
  .main-page__table {
    border-spacing: 0px;
    margin: 20px 10px 0px 20px;
    width: 60%;
    border: solid 2px black;
    border-top: none;
  }
  
  .main-page__text-not-selected {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    width: 100%;
  }
  
  .main-page__product-categories {
    display: flex;
    flex-direction: column;
    width: 30%;
    border: solid #282c34;
    padding: 10px 25px 10px 5px;
    margin-top: 15px;
    border-top: none;
    height: min-content;
    max-height: calc(85vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .main-page {
    display: flex;
    justify-content: center;
  }
  
  button {
    cursor: pointer;
  }
.box-video {
  display: flex;
  text-align: center;
}

.box-table-title__btn {
  font-size: medium;
  font-weight: 600;
  border: solid 2px black;
  border-radius: 5px;
  margin: 5px;
  width: 100px;
}

.box-table-title__btn:hover {
  background-color: #ff7b00a9;
}

.box-table-title__btn:disabled:hover {
  background-color: #ff0000a9;
}

.box-table-title__btn:disabled {
  border: solid 2px rgba(0, 0, 0, 0.459);
}

label {
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
select {
  height: 25px;
  font-size: medium;
  font-weight: 600;
  border: solid 2px black;
  border-radius: 5px;
  margin: 5px;
  width: 180px;
}
.box-table-title__text {
  height: 25px;
  margin: 13px 0px;
}

.box-table-title {
  font-weight: 600;
  font-size: 18px;
  border: solid 2px black;
  border-bottom: none;
  padding: 10px;
  background-color: rgb(250, 250, 250);
}

.box-table {
  border-spacing: 0px;
  margin: 20px 10px 0px 20px;
  width: 90%;
  max-width: 1300px;
  border: solid 2px black;
  border-top: none;
  height: min-content;
}
.table-item__th {
  background-color: rgb(253, 207, 146);
  border-right: solid 1px black;
  border-top: solid 1px black;
}

.form__table {
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: calc(90vh - 110px);
  overflow-x: hidden;
  overflow-y: auto;
}

.box-table-title__btn {
  font-size: medium;
  font-weight: 600;
  border: solid 2px black;
  border-radius: 5px;
  margin: 5px;
  width: 100px;
}

.box-btn {
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.box-table-title__btn:hover {
  background-color: #ff7b00a9;
}

.box-table-title__first-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

/* ///------------------------------------------------- */

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0.5rem 0;
}

.pagination .text {
  opacity: 0.6;
  font-size: 1.5rem;
}

.page {
  color: rgb(3, 3, 3);
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  border: solid 0.1rem rgb(255, 145, 0);
  background-color: rgb(253, 207, 146);
  border-radius: 0.7rem;
  cursor: pointer;
}

.page.active,
.input-change-page.active,
.input-change-page:hover,
.page:hover {
  background-color: #ff7b00a9;
}

.page.disabled {
  background-color: transparent !important;
  cursor: not-allowed;
  opacity: 0.5;
}

.items {
  margin: 0 auto;
  margin-top: 4rem;
  width: clamp(35rem, 60vw, 50rem);
}

.input-change-page {
  opacity: 0.8;
  font-size: 1.8rem;
  max-width: 90px;
  border-radius: 10px;
  min-width: 50px;
  background-color: rgb(253, 207, 146);
  color: black;
  border: solid 0.1rem rgb(255, 145, 0);
  height: 42px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ///------------------------------------------------- */


